import React from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';

// Assets
import einstein from 'assets/images/einstein1logo.png';
import ft from 'assets/images/ftlogo.png';
import minkz from 'assets/images/minkz.png';
import layerdeals from 'assets/images/layerdeals-logo.png';

// Styles
import './css/navigation.scss';

// Components
import Footer from './Footer';

function Partner() {
  return (
    <>
      <Helmet>
        <title>Flowdust - Unsere Partner</title>
      </Helmet>

      <div className="has-background-white">
        <h1 className="is-uppercase mb50 pt30 has-text-weight-semibold is-size-1-desktop is-size-4-tablet is-size-5-mobile has-text-centered has-text-black">Unsere Partner</h1>

        <section>
          <div className="mt10 columns  is-centered">
            <div className="column is-2 is-4-mobile is-offset-4-mobile has-text-centered">
              <a
                aria-label="LayerDeals.com"
                title="LayerDeals.com"
                target="_blank"
                rel="noopener noreferrer"
                href=" https://layerdeals.com/ "
              >
                <img
                  className="br22 has-width-150"
                  src={layerdeals}
                  alt="LayerDeals.com logo"
                />
              </a>
            </div>
          </div>
          <div className="mb40 columns is-centered">
            <div className="column is-4 ">
              <h2 className="ml10 mr10 has-text-weight-medium has-text-centered">
                LayerDeals.com - Die besten Deals für 3D Drucker, Filament und Mehr
              </h2>
              <p className="mt10 ml10 mr10 has-text-weight-medium has-text-centered">
                LayerDeals ist eine Plattform, die es dir ermöglicht, die besten Angebote für 3D-Drucker, Filament und Zubehör zu finden. Egal, ob du ein Anfänger oder ein erfahrener 3D-Druck-Enthusiast bist, LayerDeals.com hat alles, was du brauchst.
              </p>
            </div>
          </div>
        </section>

        <div className="mt20 columns is-centered">
          <div className="column is-2 is-7-mobile is-offset-2-mobile">
            <a href="https://www.einstein1.net">
              <img src={einstein} alt="Logo Digitales Gründerzentrum Einstein1" />
            </a>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-4">
            <p className="ml10 mr10 has-text-weight-medium has-text-centered">{'Digitales Gründerzentrum am Campus der Hochschule Hof. Beratung, Businessplancoaching und Events für Startups und Gründer. '}</p>
          </div>
        </div>
        <div className="mb30 lineh2" />
        <div className="mt10 columns  is-centered">
          <div className="column is-1 is-4-mobile is-offset-4-mobile">
            <a href=" https://www.fehmel-thillmann.de/ ">
              <img src={ft} alt="Logo Internetagentur Fehmel und Thillmann" />
            </a>
          </div>
        </div>
        <div className="mb40 columns is-centered">
          <div className="column is-4 ">
            <p className="ml10 mr10 has-text-weight-medium has-text-centered">Internetagentur aus Marktredwitz. Modernes Design & Konzept. Zuverlässige Programmierung und Betreuung für Webseiten, Webshops und Apps.</p>
          </div>
        </div>
        <div className="mb30 lineh2" />
        <div className="mt10 columns  is-centered">
          <div className="column is-2 is-4-mobile is-offset-4-mobile">
            <a href=" https://www.minkz.de/ ">
              <img className="br22" src={minkz} alt="Logo Minkz Gaming" />
            </a>
          </div>
        </div>
        <div className="mb40 columns is-centered">
          <div className="column is-4 ">
            <p className="ml10 mr10 has-text-weight-medium has-text-centered">
              MINKZ - Deine E-Sport-Talentschmiede
            </p>
            <p className="mt10 ml10 mr10 has-text-weight-medium has-text-centered">
              MINKZ bringt Gamer-Talente in den professionellen E-Sport und nutzt für die Ausbildung und Förderung seiner Spieler und Teams ein im E-Sport einzigartiges Nachwuchskonzept.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default (Partner);
